import React, { useContext, useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getShopifyImage } from 'gatsby-source-shopify';
import styled from 'styled-components';
import { standardColours, brandColours, fontSize } from '../styles';
import ProductQuantity from './ProductQuantity';
import {
  checkIfMural,
  useVariantPrice,
  formatPrice,
  translateString,
} from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledCartItem = styled.div`
  display: grid;
  grid-template-columns: 104px 1fr;
  gap: 15px;
`;

const StyledDetails = styled.div``;

const StyledHeading = styled.h3`
  ${fontSize(14)};
`;

const StyledText = styled.p`
  margin-top: 15px;
  color: ${standardColours.darkerGrey};
  ${fontSize(12)};
`;

const StyledQuantityPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const StyledQuantity = styled(ProductQuantity)`
  margin-right: 10px;
`;

const StyledPrice = styled.p``;

const StyledRemove = styled.button`
  margin-top: 15px;
  padding: 0;
  color: ${brandColours.quinary};
  ${fontSize(12)};
  background: none;
  border: none;
`;

const CartItem = ({
  product: { id, title, quantity: currentQuantity, variant },
  locale,
}) => {
  const { image, title: variantTitle, sku, selectedOptions } = variant;

  const { updateProductInCheckout, removeProductFromCheckout } =
    useContext(StoreContext);

  const imageData = getShopifyImage({
    image: { originalSrc: image.src },
    layout: 'fixed',
    width: 104,
    height: 128,
  });

  const isMural = checkIfMural(sku);

  const isSample = selectedOptions.find(
    ({ name, value }) => name === 'Product Type' && value === 'Sample'
  );

  const [quantity, setQuantity] = useState(currentQuantity);

  const variantPrice = useVariantPrice(variant);

  useEffect(() => {
    setQuantity(currentQuantity);
  }, [currentQuantity]);

  const changeQuantity = async e => {
    const value = e.target.value;
    let changedQuantity;

    if (value === 'remove') {
      changedQuantity = quantity > 0 ? quantity - 1 : 0;
    } else if (value === 'add') {
      changedQuantity = quantity ? quantity + 1 : 1;
    } else {
      changedQuantity = parseInt(e.target.value);
    }

    await updateProductInCheckout(id, changedQuantity);
    setQuantity(changedQuantity);
  };

  return (
    <StyledCartItem>
      <GatsbyImage image={imageData} alt={image.altText || title} />
      <StyledDetails>
        <StyledHeading>{title}</StyledHeading>
        {variantTitle !== 'Default Title' && (
          <StyledText>
            {variantTitle.replace('Self Adhesive', 'Peel & Stick')}
            {sku && ` (${sku})`}
          </StyledText>
        )}
        <StyledQuantityPrice>
          {!isMural && !isSample && (
            <StyledQuantity
              quantity={quantity}
              changeFunction={changeQuantity}
              isCartItem={true}
            />
          )}
          <StyledPrice>
            {formatPrice({
              ...variantPrice,
              amount: isMural
                ? quantity * variantPrice.amount
                : variantPrice.amount,
            })}
          </StyledPrice>
        </StyledQuantityPrice>
        <StyledRemove onClick={() => removeProductFromCheckout(id)}>
          {translateString('cart.remove', locale)}
        </StyledRemove>
      </StyledDetails>
    </StyledCartItem>
  );
};

export default CartItem;
