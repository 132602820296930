import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { standardColours, selectWrapStyles, selectStyles } from '../styles';
import PopOutSidebar, { StyledContent } from './PopOutSidebar';
import { currencyOptions, getFlag, buildUrl } from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledLocationSettings = styled(PopOutSidebar)`
  right: 0;
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledSelector = styled.label`
  margin: 15px 0;
`;

const StyledLabel = styled.span`
  display: block;
  margin-bottom: 15px;
`;

const StyledSelectWrap = styled.span`
  ${selectWrapStyles()};
`;

const StyledFlag = styled.img`
  position: absolute;
  top: 50%;
  left: 15px;
  width: 18px;
  height: 18px;
  object-fit: cover;
  border-radius: 50%;
  transform: translateY(-50%);
`;

const StyledSelect = styled.select`
  ${selectStyles()};
  height: 55px;
  border-radius: 5px;
  outline-color: ${standardColours.black};

  ${({ name }) => {
    if (name === 'currency') {
      return css`
        padding-left: 40px;
      `;
    }
  }}
`;

export const CurrencySelector = () => {
  const { currency, changeCurrency, toggleLocationSettingsOpen } =
    useContext(StoreContext);

  const handleChange = e => {
    changeCurrency(e.target.value);
    toggleLocationSettingsOpen();
  };

  return (
    <StyledSelector>
      <StyledLabel>Currency:</StyledLabel>
      <StyledSelectWrap>
        <StyledFlag src={getFlag(currency)} alt={currency} loading="lazy" />
        <StyledSelect name="currency" onChange={e => handleChange(e)}>
          {currencyOptions.map((currencyOption, id) => (
            <option
              key={id}
              value={currencyOption}
              selected={currencyOption === currency}
            >
              {currencyOption}
            </option>
          ))}
        </StyledSelect>
      </StyledSelectWrap>
    </StyledSelector>
  );
};

export const LanguageSelector = ({
  locale: currentLocale,
  slugLocales,
  page,
}) => {
  const languages = [
    {
      locale: 'en',
      name: 'English',
    },
    {
      locale: 'fr',
      name: 'French',
    },
  ];

  const { currency, changeCurrency, toggleLocationSettingsOpen } =
    useContext(StoreContext);

  const handleChange = e => {
    const locale = e.target.value;

    const slugLocale = slugLocales.find(item => item.locale === locale);

    let url = '/';

    if (slugLocale) {
      url = buildUrl(slugLocale.value, {
        ...page,
        locale: locale,
      });
    } else if (locale !== 'en') {
      url = buildUrl(locale);
    }

    changeCurrency(currency);
    navigate(url);
    toggleLocationSettingsOpen();
  };

  return (
    <StyledSelector>
      <StyledLabel>Language:</StyledLabel>
      <StyledSelectWrap>
        <StyledSelect name="language" onChange={e => handleChange(e)}>
          {languages.map(({ locale, name }, id) => (
            <option key={id} value={locale} selected={locale === currentLocale}>
              {name}
            </option>
          ))}
        </StyledSelect>
      </StyledSelectWrap>
    </StyledSelector>
  );
};

const LocationSettings = ({ locale, slugLocales, page }) => {
  const { isLocationSettingsOpen, toggleLocationSettingsOpen } =
    useContext(StoreContext);

  return (
    <StyledLocationSettings
      heading="Location Settings"
      isOpen={isLocationSettingsOpen}
      toggleOpen={toggleLocationSettingsOpen}
    >
      <StyledInner>
        <StyledContent>
          <CurrencySelector />
          <LanguageSelector
            locale={locale}
            slugLocales={slugLocales}
            page={page}
          />
        </StyledContent>
      </StyledInner>
    </StyledLocationSettings>
  );
};

export default LocationSettings;
